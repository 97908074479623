import { useCallback, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OrderContext } from "../components/providers/OrderProvider";

const OrderForm = () => {
  const { order, setOrder } = useContext(OrderContext);
  const location = useLocation();
  const navigate = useNavigate();

  const isNotReadyToContinue =
    order.shipping.method === "" ||
    (order.shipping.method === "zasilkovna-pickup" &&
      Object.keys(order.shipping.zasilkovnaPoint).length === 0) ||
    order.type === "" ||
    order.status === "" ||
    order.basket.length === 0 ||
    order.total === 0;

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitOrderForm = async (e) => {
    e.preventDefault();
    if (!order.paymentMethod) {
      navigate("/payment-choice");
      return;
    }

    setIsLoading(true);

    try {
      let res = null;
      if (order.paymentMethod === "stripe") {
        res = await fetch("/php/stripe-checkout.php", {
          method: "POST",
          body: JSON.stringify({
            ...order,
            success_url: "/order-complete",
            cancel_url: location.pathname,
          }),
        });
      }
      if (order.paymentMethod === "bankTransfer") {
        res = await fetch("/php/invoice-checkout.php", {
          method: "POST",
          body: JSON.stringify({
            ...order,
            success_url: "/order-complete",
            cancel_url: location.pathname,
          }),
        });
      }
      if (!res) {
        return;
      }
      let resJson = await res.json();
      if (res.status === 200) {
        setPaymentResponse(resJson);
        if (resJson.redirectUrl.includes("/order-complete")) {
          navigate(resJson.redirectUrl);
        } else {
          window.location.replace(resJson.redirectUrl);
        }
        return;
      } else {
        console.error(res.status);
        alert("🚧 Chyba " + res.status + ": " + res.text());
      }
    } catch (err) {
      console.log(err);
      alert("🚧 Něco se pokazilo. Zkus to znovu.");
    }

    setIsLoading(false);
  };

  const setPaymentResponse = ({ redirectUrl, h1, h2, invoice }) => {
    localStorage.setItem(
      "order",
      JSON.stringify({
        ...order,
        orderComplete: {
          redirectUrl,
          h1,
          h2,
          invoice,
        },
      })
    );

    setOrder({
      ...order,
      orderComplete: {
        redirectUrl,
        h1,
        h2,
        invoice,
      },
    });
  };

  const onInputChange = useCallback((e) => {
    const newOrder = order;
    newOrder.customer[e.target.id] = e.target.value;

    localStorage.setItem(
      "order",
      JSON.stringify({
        ...newOrder,
      })
    );

    setOrder({
      ...newOrder,
    });
  }, []);

  const onCheckboxChange = useCallback((e) => {
    const newOrder = order;
    let heurekaConsent = true;
    if (e.target.checked) {
      heurekaConsent = false;
    }
    newOrder.customer[e.target.id] = heurekaConsent;

    localStorage.setItem(
      "order",
      JSON.stringify({
        ...newOrder,
      })
    );

    setOrder({
      ...newOrder,
    });
  }, []);

  return (
    <form
      className="w-full self-stretch flex flex-col items-center justify-start gap-[2.5rem] max-w-[40rem] my-0 mx-auto"
      action="/php/stripe-checkout.php"
      method="post"
      id="delivery-info"
      onSubmit={onSubmitOrderForm}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem]">
        <div className="self-stretch flex flex-row items-start justify-start gap-[1.5rem] sm:flex-col sm:gap-[1.5rem] sm:items-start sm:justify-start">
          <input
            className="[border:none] font-graphie text-[1rem] bg-color-grey-100 flex-1 rounded-full overflow-hidden flex flex-row py-[1.25rem] px-[1.5rem] items-start justify-start sm:flex-[unset] sm:self-stretch"
            type="text"
            placeholder="Jméno"
            required
            name="name"
            id="name"
            onChange={onInputChange}
            value={order.customer.name}
          />
          <input
            className="[border:none] font-graphie text-[1rem] bg-color-grey-100 flex-1 rounded-full overflow-hidden flex flex-row py-[1.25rem] px-[1.5rem] items-start justify-start sm:flex-[unset] sm:self-stretch"
            type="text"
            placeholder="Příjmení"
            required
            name="surname"
            id="surname"
            onChange={onInputChange}
            value={order.customer.surname}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start">
          <input
            className="[border:none] font-graphie text-[1rem] bg-color-grey-100 self-stretch rounded-full overflow-hidden flex flex-row py-[1.25rem] px-[1.5rem] items-start justify-start"
            type="email"
            placeholder="E-mail"
            required
            name="email"
            id="email"
            onChange={onInputChange}
            value={order.customer.email}
          />
          <div className="self-stretch flex flex-row py-[0.5rem] px-[1.5rem] items-start justify-start">
            <div className="flex-1 relative text-[0.88rem] leading-[100%] font-graphie text-dimgray text-left">
              Zašleme na něj potvrzení objednávky.
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start">
          <input
            className="[border:none] font-graphie text-[1rem] bg-color-grey-100 self-stretch rounded-full overflow-hidden flex flex-row py-[1.25rem] px-[1.5rem] items-start justify-start"
            type="tel"
            placeholder="Telefon"
            required
            name="phone"
            id="phone"
            onChange={onInputChange}
            value={order.customer.phone}
          />
          <div className="self-stretch flex flex-row py-[0.5rem] px-[1.5rem] items-start justify-start">
            <div className="flex-1 relative text-[0.88rem] leading-[100%] font-graphie text-dimgray text-left">
              Zásilkovna na něj pošle doručovací PIN.
            </div>
          </div>
        </div>
        <input
          className="[border:none] font-graphie text-[1rem] bg-color-grey-100 self-stretch rounded-full overflow-hidden flex flex-row py-[1.25rem] px-[1.5rem] items-start justify-start"
          type="text"
          placeholder="Ulice"
          required
          name="street"
          id="street"
          onChange={onInputChange}
          value={order.customer.street}
        />
        <div className="self-stretch flex flex-row items-start justify-start gap-[1.5rem]">
          <input
            className="[border:none] font-graphie text-[1rem] bg-color-grey-100 flex-1 rounded-full overflow-hidden flex flex-row py-[1.25rem] px-[1.5rem] items-start justify-start"
            type="text"
            placeholder="PSČ"
            required
            name="postalCode"
            id="postalCode"
            onChange={onInputChange}
            value={order.customer.postalCode}
          />
          <input
            className="[border:none] font-graphie text-[1rem] bg-color-grey-100 flex-1 rounded-full overflow-hidden flex flex-row py-[1.25rem] px-[1.5rem] items-start justify-start"
            type="text"
            placeholder="Město"
            required
            name="city"
            id="city"
            onChange={onInputChange}
            value={order.customer.city}
          />
        </div>
        <select
          className="self-stretch rounded-full text-[1rem] font-graphie bg-color-grey-100 flex flex-row py-[1.25rem] px-[1.5rem] box-border items-center justify-end appearance-none bg-chevron-down bg-no-repeat bg-[right_1.25rem_center]"
          required
          id="country"
          name="country"
          onChange={onInputChange}
          value={order.customer.country}
        >
          <option value="cz" defaultValue>
            Česká republika
          </option>
          <option value="sk">Slovensko</option>
        </select>
      </div>

      <div className="inline-flex items-center gap-2">
        <label
          className="relative flex items-center rounded-full cursor-pointer"
          htmlFor="heurekaConsent"
        >
          <input
            type="checkbox"
            className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-solid border-color-grey-600 transition-all before:absolute before:top-1/2 before:left-1/2 before:block before:h-12 before:w-12 before:-translate-y-1/2 before:-translate-x-1/2 before:rounded-full before:bg-color-gray-600 before:opacity-0 before:transition-opacity checked:border-color-black checked:bg-color-black checked:before:bg-color-gray-600 hover:before:opacity-10"
            id="heurekaConsent"
            onChange={onCheckboxChange}
            checked={order.customer.heurekaConsent ? false : true}
          />
          <span className="absolute flex items-center justify-center w-5 h-5 text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 peer-checked:opacity-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3.5 w-3.5"
              viewBox="0 0 20 20"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="1"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </span>
        </label>
        <label
          className="mt-px font-graphie w-full text-dimgray text-[0.875rem] leading-snug"
          htmlFor="heurekaConsent"
        >
          Nesouhlasím se zasláním dotazníku spokojenosti v rámci programu
          Ověřeno zákazníky, který pomáhá zlepšovat naše služby.
        </label>
      </div>

      <div className="flex flex-col items-center w-full gap-[1.5rem]">
        {isNotReadyToContinue && (
          <span className="font-graphie w-full text-dimgray text-[0.875rem] text-center">
            Některé informace o objednávce chybí. Vraťte se na začátek a ověřte,
            že jste vše vyplnili.
          </span>
        )}
        {!isLoading && (
          <button
            className="hover:bg-gray min-w-[15rem] cursor-pointer [border:none] py-[1.25rem] px-[2.5rem] bg-color-black text-color-white rounded-full overflow-hidden flex flex-row items-center justify-center disabled:bg-color-grey-300 disabled:text-dimgray/50 disabled:cursor-not-allowed"
            disabled={isNotReadyToContinue || isLoading}
            title={
              isNotReadyToContinue
                ? "Některé informace o objednávce chybí. Vraťte se na začátek a ověřte, že jste vše vyplnili."
                : ""
            }
          >
            <b className="relative text-[1rem] font-graphie text-center leading-none">
              {order.paymentMethod === "stripe" ? "Pokračovat k platbě" : ""}
              {order.paymentMethod === "bankTransfer"
                ? "Dokončit objednávku"
                : ""}
            </b>
          </button>
        )}
        {isLoading && (
          <div className="py-[0.75rem] min-w-[15rem] inline-flex items-center justify-center rounded-full bg-color-grey-300">
            <span className="w-[1.5rem] h-[1.5rem] border-solid rounded-full border-[0.25rem] border-dimgray border-r-transparent animate-spin"></span>
          </div>
        )}

        <span className="font-graphie w-full text-dimgray text-[0.875rem] text-center leading-snug">
          Pokračováním souhlasíte s{" "}
          <Link
            to="https://hairathome.cz/obchodni-podminky.html"
            target="_blank"
            className="text-dimgray whitespace-nowrap"
          >
            obchodními podmínkami
          </Link>{" "}
          a{" "}
          <Link
            to="https://hairathome.cz/ochrana-osobnich-udaju.html"
            target="_blank"
            className="text-dimgray whitespace-nowrap"
          >
            podmínkami zpracování osobních údajů
          </Link>
          .
        </span>
      </div>
    </form>
  );
};

export default OrderForm;
